const isNewFlowAvailableFor = (type) => {
  // TODO REFACTOR: introduce constants in integration lib and use them here
  return [
    'shopifyCustomer',
    'klaviyo',
    'sendinblue',
    'mailChimp',
    'activeCampaign',
    'hubSpotV2',
    'webhook',
    'highLevel',
    'dotmailer',
    'recart',
    'selzy',
    'unas',
  ].includes(type);
};

const FLOW_STATES = {
  NEW: 'new',
  EDIT: 'edit',
  SELECT_GLOBAL: 'select',
};

export { isNewFlowAvailableFor, FLOW_STATES };
